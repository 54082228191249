import React from 'react';
import { navigate } from 'gatsby';
import { Link } from '@reach/router';
import { ExpertApproval, HowItWorks, SwiperComponent, TrustPilot } from 'vitl-component-library';
// components
import HeroHomepage from '../components/hero-homepage/hero-homepage';
import PartnersSlider from '@components/partners-slider/partners-slider';
import { useHomeText } from '@hooks/directusHooks/useHomeText';
import Image from '@components/image/image';
import RecommendedProducts from '@src/features/cross-sell/recommended-products/recommended-products';
import Layout from '@components/layout';
import SEO from '@components/seo';
import SectionStandard from '@components/section-standard';
import Reviews from '@components/reviews/reviews';
// styles
import styles from './index.module.scss';

interface IProps {}

const IndexPage: React.FC<IProps> = () => {
  const directusHome = useHomeText();

  return (
    <Layout>
      <SEO title={directusHome.page_title} description={directusHome.page_description} />

      <HeroHomepage
        title={directusHome.hero_title}
        description={directusHome.hero_description}
        buttonLabel={directusHome.hero_button_label}
        backgroundImage={directusHome.hero_background_image}
        readLabel={directusHome.hero_read_label}
      />

      <section className={styles.section}>
        <div className={styles.vitaminsForYou}>
          <h2>{directusHome.vitamins_header}</h2>
          <hr />
          <div className={styles.textWrap}>
            <p>{directusHome.vitamins_text}</p>
            <p>
              <strong>{directusHome.vitamins_bold}</strong>
            </p>
          </div>
          <div className={styles.icons}>
            {directusHome.vitamins_icons.map((icon, index) => (
              <div key={icon.image} className={styles.icon}>
                <div className={`${styles.imageWrap} ${styles[`image${index}`]}`}>
                  <Image filename={icon.image} />
                </div>
                <p>{icon.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <PartnersSlider />

      <HowItWorks title={directusHome.how_it_works_title} items={directusHome.how_it_works_items} />

      <Reviews
        title={directusHome.reviews_title}
        items={directusHome.reviews_items}
        showReview
        variationLightYellow
        variationYellow={false}
        hideTrustPilot={false}
        trustpilotIframe={<TrustPilot type={'MicroCombo'} />}
      />

      {/* benefits view */}
      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.benefitsImage}>
          <Image filename={directusHome.benefits_background_images.filename_disk} width={660} />
        </div>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{directusHome.benefits_title}</h2>
              <hr className={styles.hr} />
              <p>{directusHome.benefits_description}</p>
            </div>
          </div>
          <div className={styles.contentSwiper}>
            <SwiperComponent paginationType="bullets" spaceBetween={16} slidesPerView={1}>
              {directusHome.benefits_items.map((item: any, index: number) => (
                <div key={index}>
                  <div className={styles.slides}>
                    <Image filename={item.filename_disk} alt={item.title} />
                  </div>
                  <div className={`${styles.divider} ${styles.dividerCap}`} />
                  <h3 className={styles.slidesTitle}>{item.title}</h3>
                  <hr />
                  <p>{item.description}</p>
                  <Link to={item.link} className={styles.link}>
                    {item.link_text}
                  </Link>
                </div>
              ))}
            </SwiperComponent>
          </div>
          <div className={styles.row}>
            <div className={styles.contentBenefits}>
              {directusHome.benefits_items.map((item: any, index: number) => (
                <div className={styles.benefitsItem} key={index}>
                  <h3>{item.title}</h3>
                  <div className={`${styles.divider} ${styles.dividerCap}`} />
                  <p>{item.description}</p>
                  <Link to={item.link} className={styles.link}>
                    {item.link_text}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={styles.crossSell}>
        <div>
          <h2>{directusHome.discover_products_header}</h2>
          <RecommendedProducts products={directusHome.discover_products} />
        </div>
      </section>

      {/* ingredient integrity */}
      <SectionStandard
        title={directusHome.ingredient_title}
        description={directusHome.ingredient_description}
        backgroundImage={directusHome.ingredient_desktop_background_image}
        customClass={styles.integrityDesktopWrapper}
      />
      <div
        className={styles.integrityMobileWrapper}
        style={{
          backgroundImage: `url(${process.env.GATSBY_MEDIA_URL}2b4c5f74-c90b-41aa-b55c-46c1319e7558.jpeg?w=767)`,
        }}
      >
        <h2>{directusHome.ingredient_title}</h2>
        <hr />
        <div
          className={styles.ingredientContent}
          dangerouslySetInnerHTML={{
            __html: directusHome.ingredient_description,
          }}
        />
        <div className={styles.ingredientDividerImage}>
          <Image
            width={90}
            height={140}
            className={styles.logos}
            filename={'94ee20a0-af80-4721-8839-27e289f87d24.png'}
          />
        </div>
      </div>

      <ExpertApproval
        title={directusHome.expert_approval_title}
        description={directusHome.expert_approval_description}
        experts={directusHome.experts}
      />

      {/* reliability Commitment */}
      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contentReliability}>
              <h2>{directusHome.reliability_title}</h2>
              <hr />
              <p>{directusHome.reliability_description}</p>
            </div>
            <div className={styles.icons}>
              {directusHome.reliability_items.map((item: any, index: number) => (
                <Image key={index} width={166} height={80} className={styles.logos} filename={item.image_hash} />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* feel more you */}
      <section className={styles.sectionPrimary}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.feelImage}>
              <Image
                filename={directusHome.feel_more_you_image.filename_disk}
                alt={directusHome.feel_more_you_image.title}
                width={261}
              />
            </div>
            <div className={styles.contentFeelWrapper}>
              <div className={styles.contentFeel}>
                <h2>{directusHome.feel_more_you_title}</h2>
                <hr className={styles.hrDark} />
                <div
                  className={styles.feelDescription}
                  dangerouslySetInnerHTML={{
                    __html: directusHome.feel_more_you_description,
                  }}
                />
                <button className={styles.buttonFeel} type="button" onClick={() => navigate('/consultation')}>
                  {directusHome.feel_more_you_button_label}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
