import { graphql, useStaticQuery } from 'gatsby';

export const useHomeText = () => {
  const { directusHome } = useStaticQuery(graphql`
    query Home {
      directusHome {
        page_title
        page_description
        hero_title
        hero_description
        hero_read_label
        hero_image {
          title
          filename_disk
        }
        hero_background_image {
          filename_disk
        }
        hero_mobile_background_image {
          filename_disk
        }
        hero_button_label
        how_it_works_title
        how_it_works_items {
          title
          description
          filename_disk
        }
        reviews_title
        reviews_items {
          reviewerName
          review
        }
        expert_approval_title
        expert_approval_description
        experts {
          name
          qualification
          role
          image {
            title
            filename_disk
          }
        }
        feel_more_you_title
        feel_more_you_description
        feel_more_you_image {
          title
          filename_disk
        }
        feel_more_you_button_label
        ingredient_title
        ingredient_description
        ingredient_mobile_background_image {
          title
          filename_disk
        }
        ingredient_desktop_background_image {
          title
          filename_disk
        }
        reliability_title
        reliability_description
        reliability_image {
          filename_disk
        }
        reliablity_logos_image {
          filename_disk
        }
        reliability_items {
          image_hash
        }
        benefits_title
        benefits_description
        benefits_background_images {
          filename_disk
        }
        benefits_items {
          title
          description
          filename_disk
          link
          link_text
        }
        vitamins_header
        vitamins_text
        vitamins_bold
        vitamins_icons {
          image
          text
        }
        discover_products_header
        discover_products_show_more_text
        discover_products {
          name
          description
          short_description
          identifier
          filename_disk
          route
          best_seller
          selling_fast
          low_stock
          out_of_stock
          digital_product
          background_color
        }
      }
    }
  `);
  return directusHome;
};
