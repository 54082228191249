import React from 'react';
import { navigate } from 'gatsby';
import { TrustPilot } from 'vitl-component-library';
// interfaces
import { IImage } from '../../interfaces/image';
import Image from '@components/image/image';
// styles
import styles from './hero-homepage.module.scss';

interface IProps {
  title: string;
  description: string;
  buttonLabel: string;
  backgroundImage?: IImage;
  readLabel: string;
}

const HeroHomepage: React.FC<IProps> = ({
  title,
  description,
  buttonLabel,
  backgroundImage,
  readLabel,
}: IProps) => {
  return (
    <section className={backgroundImage ? styles.heroBackground : styles.hero}>
      <picture className={styles.heroBackgroundImage}>
        {backgroundImage && <Image filename={backgroundImage.filename_disk} noBlurLoading />}
      </picture>

      <div className={styles.container}>
        <div className={styles.mobileImagesWrapper}>
          <Image
            noBlurLoading
            className={styles.topLeft}
            alt="Vitl product 1"
            filename="51158446-59b4-4622-be38-b76eebd6c356.jpeg"
          />
          <Image
            noBlurLoading
            className={styles.topRight}
            alt="Vitl product 2"
            filename="66e5f855-3952-43b5-9dbf-293a83cbdf13.jpeg"
          />
          <Image
            noBlurLoading
            className={styles.bottomRight}
            alt="Vitl product 3"
            filename="55aa883b-c86a-4b87-b5bb-f7c523353bc5.jpeg"
          />
          <Image
            noBlurLoading
            className={styles.bottomLeft}
            alt="Vitl product 4"
            filename="1032d109-272e-42db-8c15-f7f8793bf084.jpeg"
          />
        </div>
        <div className={styles.row}>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <h1 className={styles.h1}>{title}</h1>
              <hr className={styles.hr} />
              <p className={styles.description}>{description}</p>
              <button
                className={styles.button}
                type="button"
                onClick={() => navigate('/consultation')}
                data-testid="home-consultation"
              >
                {buttonLabel || 'Take Health Quiz'}
              </button>
              <div className={styles.trustPilotHeroWrapper}>
                <TrustPilot type={'MicroStar'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroHomepage;
